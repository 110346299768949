const Images = {
	logo: require('@/assets/logo/logo.png'),
	tab: require('@/assets/tab.png'),
	thumbs: [
		require('@/assets/images/thumbs/1.svg'),
		require('@/assets/images/thumbs/2.svg'),
		require('@/assets/images/thumbs/3.svg'),
		require('@/assets/images/thumbs/4.svg'),
		require('@/assets/images/thumbs/5.svg'),
		require('@/assets/images/thumbs/6.svg'),
	]
}
export default Images;