import { DirService } from '../utils/api.service';
export default {
	namespaced: true,
	state: {
	},
	actions: {
		getDir({ dispatch, commit }, payload) {
			return DirService.getDir(payload).then(res => {
				if (res.data.status !== 400) {
					return res.data.data;
				} else {
					return false;
				}
			});
		},
		addDir({ dispatch, commit }, payload) {
			return DirService.addDir(payload).then(res => {
				if (res.data.status !== 400) {
					dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
					return res.data.data;
				} else {
					dispatch('app/setNotification', { status: 'warning', text: res.data.message }, { root: true });
					return false;
				}
			});
		},
		removeDir({ dispatch, commit }, payload) {
			return DirService.deleteDir(payload).then(res => {
				if (res.data.status !== 400) {
					dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
					return true;
				} else {
					dispatch('app/setNotification', { status: 'warning', text: res.data.message }, { root: true });
					return false;
				}
			});
		},
		updateDir({ dispatch, commit }, payload) {
			return DirService.updateDir(payload).then(res => {
				if (res.data.status !== 400) {
					dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
					return true;
				} else {
					dispatch('app/setNotification', { status: 'warning', text: res.data.message }, { root: true });
					return false;
				}
			});
		},
	}
}