/*
 * @Author: niweixing
 * @Date: 2022-12-20 22:47:37
 * @LastEditors: niweixing
 * @LastEditTime: 2022-12-21 00:29:25
 * @Description: okr列表，根据每个okr的可见度，与当前登录用户做过滤，是否对当前用户展示
 */
import { getUser } from "@/utils/authUser.service";
import store from "@/store"; //引用store

export const filterVisible = (okrList) => {
  //okr列表的可见范围的权限过滤（0:全公司, 1:仅相关成员 2:仅直接下属 3:指定范围） 供调接口返回数据后的过滤
  if (getUser().user_role == "admin" || getUser().user_role == "creator") {
    //超级管理员与助理 不做过滤
    return okrList;
  }
  if (okrList instanceof Array) {
    let currentUser = getUser().id; //当前登录的用户id
    okrList.forEach((item, index) => {
      switch (item.ob_visible_type) {
        case 0: //可见范围全公司 不做过滤
          break;

        case 1: //可见范围仅相关成员
          let isAdmin = item.ob_owner === currentUser; //是否为okr负责人
          let attentionArr = item.ob_attention.split(",");
          let isAttention = attentionArr.includes(String(currentUser)); //是否为参与者
          if (!isAdmin && !isAttention) {
            //（当前登录用户不在相关成员中 过滤）
            return okrList.splice(index, 1);
          }
          break;

        case 2: //仅直接下属
          let subordinate_users = store.state.user.users.filter((user) => {
            return user.user_superior == item.ob_owner; //项目创建者 筛选他的下属
          });
          let isContaincurrentUser = false;
          subordinate_users.map((item) => {
            if (item.id === currentUser) {
              isContaincurrentUser = true;
            }
          });
          return !isContaincurrentUser && okrList.splice(index, 1); //当前okr不包含当前用户 删除当前okr
          break;
        case 3: // 可见范围指定范围
          if (item.ob_visible_range) {
            const tempArray = item.ob_visible_range.split(",");
            let isContaincurrentUser = false;
            tempArray.some((id) => {
              if (id.includes("u") && currentUser === id.substring(1)) {
                //指定用户列表存在当前登录用户刚为true
                isContaincurrentUser = true;
              } else if (
                id.includes("d") &&
                getUser().deptId == id.substring(1)
              ) {
                //指定部门存在当前用户部门为true
                isContaincurrentUser = true;
              } else {
                if (id !== "" && currentUser === id) {
                  isContaincurrentUser = true;
                }
              }
            });
            return !isContaincurrentUser && okrList.splice(index, 1); //当前okr不包含当前用户 不包含当前用户时删除当前okr
          }
          break;
      }
    });
  } else {
    return okrList;
  }
};

export default { filterVisible };
