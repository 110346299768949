/*
 * @Author: niweixing
 * @Date: 2022-12-30 01:46:02
 * @LastEditors: niweixing
 * @LastEditTime: 2023-08-25 16:41:15
 * @Description:
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import store from "@/store";
import { Routes } from "@/constants/routes";
import JwtService from "@/utils/jwt.service";
import { constant } from "@/constants/constant.js";

Vue.use(VueRouter);
Vue.use(Meta);
let notVerifyPage = [];
const routes = [
  {
    path: "/",
    name: "land page",
    redirect: !!JwtService.getToken()
      ? `${Routes.OKR_LIST_PAGE}/${constant.pageCount}/1`
      : Routes.LOGIN_PAGE,
  },
  {
    path: Routes.AUTH_PAGE,
    name: "auth",
    component: () => import("@/views/LoginView.vue"),
    children: [
      {
        path: Routes.LOGIN_PAGE,
        component: () => import("@/pages/auth/Login.vue"),
      },
      {
        path: Routes.SIGNUP_PAGE,
        component: () => import("@/pages/auth/Signup.vue"),
      },
    ],
  },
  {
    path: Routes.HOME_PAGE,
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/HomeView.vue"),
    children: [
      {
        path: Routes.OKR_GOAL_PAGE,
        component: () => import("@/pages/OKRGoal.vue"),
        children: [
          {
            path: `${Routes.OKR_LIST_PAGE}/:countsPerPage/:pageNum`,
            component: () => import("@/components/okrgoal/OKRGoalListView.vue"),
            name: "okrListPage",
          },
          {
            path: Routes.OKR_EDIT_PAGE,
            component: () =>
              import("@/components/okrgoal/okrDetail/OkrDetailComponent.vue"),
            name: "okrListPageEdit",
          },
        ],
      },
      {
        path: Routes.TASK_GANTT_PAGE,
        name: "taskGanttPage",
        component: () => import("@/pages/TaskGanttPage.vue"),
      },
      {
        path: Routes.OKR_SETTING_PAGE,
        name: "okrSettingPage",
        component: () => import("@/pages/SettingPage.vue"),
      },
      {
        path: Routes.OKR_EREPORT_PAGE,
        name: "okrEReportPage",
        component: () => import("@/pages/EReportPage.vue"),
      },
      {
        path: Routes.OKR_MAP_PAGE,
        component: () => import("@/pages/OkrMap.vue"),
        name: "okrMapPage",
      },
      {
        path: Routes.OKR_IMPORT_LIBRARY,
        component: () => import("@/pages/OKRLibraryPage.vue"),
        name: "okrLibraryPage",
      },
      {
        path: Routes.INFO_SETTING_PAGE, //个人设置
        component: () => import("@/pages/InfoSetting.vue"),
        name: "infoSettingPage",
      },
      {
        path: Routes.OKR_MESSAGE_PAGE,
        component: () => import("@/pages/MessagePage.vue"),
        name: "messagePage",
      },
      {
        path: Routes.DYNAMIC_PAGE,
        component: () => import("@/components/dynamic/DynamicPage.vue"),
        name: "dynamicPage",
      },
      {
        path: Routes.OKR_WORKING_METHOD,
        component: () => import("@/pages/OKRWorkingMethodPage.vue"),
        name: "okrMethodPage",
        children: [
          {
            path: Routes.OKR_WORKING_METHOD_HOME,
            component: () => import("@/components/workingMethod/main.vue"),
            name: "workingMethodMain",
          },
        ],
      },
      {
        path: Routes.OKR_DASHBOARD_PAGE,
        component: () => import("@/pages/OkrDashboard.vue"),
        name: "okrDashboardPage",
      },
      {
        path: Routes.EXE_SCHEDULE_PAGE,
        component: () => import("@/pages/Schedule.vue"),
        name: "schedulePage",
        redirect: Routes.EXE_SCHEDULE_PAGE_HOME,
        children: [
          {
            path: Routes.EXE_SCHEDULE_PAGE_HOME,
            component: () => import("@/components/schedule/Main.vue"),
            name: "ScheduleMain",
          },
        ],
      },
      {
        path: Routes.EXE_PROJECT_PAGE,
        component: () => import("@/pages/Project.vue"),
        name: "projectPage",
      },
      {
        path: Routes.ITEM_DETAIL_PAGE,
        component: () => import("@/pages/ItemDetailPage.vue"),
        name: "projectPageDetail",
      },
      {
        path: Routes.EXE_SUMMARY_PAGE,
        component: () => import("@/pages/Summary.vue"),
        name: "summaryParentPage",
        redirect: Routes.SUMMARY_MAIN_PAGE,
        children: [
          {
            path: Routes.SUMMARY_MAIN_PAGE,
            component: () => import("@/components/summary/Main.vue"),
            name: "summaryPage",
          },
        ],
      },
      {
        path: Routes.TASK_DETAIL_PAGE,
        component: () => import("@/pages/TaskDetail.vue"),
        name: "TaskDetail",
      },
      {
        path: Routes.WORKING_REPORT_PAGE,
        component: () => import("@/pages/WorkingReportPage.vue"),
        name: "WorkingReportPage",
      },
    ],
  },
  {
    path: Routes.SHARE_PAGE,
    name: "share",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/ShareView.vue"),
    children: [
      {
        path: Routes.TASK_PRINT_PAGE,
        name: "taskPrintPage",
        props: true,
        component: () => import("@/pages/TaskPrintPage.vue"),
      },
    ],
  },
  {
    path: Routes.ADMIN_PAGE,
    name: "admin",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/AdminView.vue"),
    children: [
      {
        path: Routes.ADMIN_EMPLOYEE_PAGE,
        component: () => import("@/pages/admin/Employee.vue"),
        name: "employeePage",
      },
      {
        path: Routes.ADMIN_RESIGN_PAGE,
        component: () => import("@/pages/admin/Resign.vue"),
        name: "resignPage",
      },
      {
        path: Routes.ADMIN_DEPARTMENT_PAGE,
        component: () => import("@/pages/admin/DepartmentManagement.vue"),
        name: "departmentPage",
      },
      {
        path: Routes.ADMIN_GROUP_PAGE,
        component: () => import("@/pages/admin/GroupManagement.vue"),
        name: "groupPage",
      },
      {
        path: Routes.ADMIN_DUTY_PAGE,
        component: () => import("@/pages/admin/Duty.vue"),
        name: "dutyPage",
      },
      {
        path: Routes.ADMIN_MANAGE_PAGE,
        component: () => import("@/pages/admin/AdminManagePage.vue"),
        name: "adminManagePage",
      },
      {
        path: Routes.ADMIN_COMPANYINFO_PAGE,
        component: () => import("@/pages/admin/CompanyInfo.vue"),
        name: "companyInfoPage",
      },
      {
        path: Routes.ADMIN_EMAIL_PAGE,
        component: () => import("@/pages/admin/EmailPage.vue"),
        name: "emailPage",
      },
      {
        path: Routes.ADVANCED_FUNC_PAGE,
        component: () => import("@/pages/admin/AdvancedFuncPage.vue"),
        name: "advancedFuncPage",
      },
    ],
  },
  // {
  //   path: Routes.POSTER_PAGE,
  //   name: "poster",
  //   props: true,
  //   meta: {
  //     requiresAuth: false, //海报页 不做登录授权管理
  //   },
  //   component: () => import("@/views/PosterView.vue"),
  //   children: [
  //     {
  //       path: Routes.POSTER_ABOUT_PAGE,
  //       name: "posterAboutPage",
  //       props: true,
  //       component: () => import("@/pages/poster/PosterAboutPage.vue"),
  //     },
  //   ],
  // },
];
const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (to.path.includes("admin")) {
    if (
      store.getters["auth/authUser"].user_role != "admin" &&
      store.getters["auth/authUser"].user_role != "editor" //admin 超管   editor后台管理员  creator 助理
    ) {
      next(`${Routes.OKR_LIST_PAGE}/${constant.pageCount}/1`);
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isAuthenticated"]) {
      next();
      return;
    }
    next(Routes.LOGIN_PAGE);
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  store.dispatch("app/setCurrentRouter", to.name, { root: true });
});
export default router;
