import { DutyService } from "../utils/api.service";

export default {
  namespaced: true,
  state: {
    duties: [],
  },
  getters: {
    duties: state => state.duties,
    // pickedduty: state => state.pickedduty
  },
  mutations: {
    getDuties(state, payload) {
      state.duties = payload;
    },
    addDuty(state, payload) {
      state.duties.push(payload);
    },
    updateDuty(state, payload) {
      const index = state.duties.findIndex(duty => duty.duty_id == payload.duty_id);
      Object.assign(state.duties[index], payload);
    },
    removeDuty(state, payload) {
      const index = state.duties.findIndex(duty => duty.duty_id == payload.duty_id);
      state.duties.splice(index, 1);
    }
  },
  actions: {
    getDuties({commit}, payload={}) {
      return new Promise((resolve, reject) => {
        DutyService.getDuties(payload).then(res => {
          if(res.status !== 400) {
            commit('getDuties', res.data.data)
            resolve();
          } else {
            reject();
          }
        })
      })      
    },
    addDuty({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        DutyService.addDuty(payload).then(res => {
          if(res.status !== 400) {
            commit('addDuty', res.data.data);
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    },
    updateDuty({ commit , dispatch }, payload) {
      return new Promise((resolve, reject) => {
        DutyService.updateDuty(payload).then(res => {
          if(res.status !== 400) {
            commit('updateDuty', payload);
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    },
    removeDuty({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        DutyService.removeDuty(payload).then(res => {
          if(res.status !== 400) {
            commit('removeDuty', payload) 
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    }
  }
}