import { GroupService } from "../utils/api.service";

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  getters: {
    groups: state => state.groups,
    // pickedGroup: state => state.pickedGroup
  },
  mutations: {
    getGroups(state, payload) {
      state.groups = payload;
    },
    addGroup(state, payload) {
      state.groups.push(payload);
    },
    updateGroup(state, payload) {
      const index = state.groups.findIndex(group => group.team_id == payload.team_id);
      Object.assign(state.groups[index], payload);
    },
    removeGroup(state, payload) {
      const index = state.groups.findIndex(group => group.team_id == payload.team_id);
      state.groups.splice(index, 1);
    }
  },
  actions: {
    getGroups({commit}, payload) {
      return new Promise((resolve, reject) => {
        GroupService.getGroups(payload).then(res => {
          if(res.status !== 400) {
            commit('getGroups', res.data.data)
            resolve();
          } else {
            reject();
          }
        })
      })      
    },
    addGroup({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        GroupService.addGroup(payload).then(res => {
          if(res.status !== 400) {
            commit('addGroup', res.data.data);
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    },
    updateGroup({ commit , dispatch }, payload) {
      return new Promise((resolve, reject) => {
        GroupService.updateGroup(payload).then(res => {
          if(res.status !== 400) {
            commit('updateGroup', payload);
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    },
    removeGroup({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        GroupService.removeGroup(payload).then(res => {
          if(res.status !== 400) {
            commit('removeGroup', payload) 
            dispatch('app/setNotification',{ status: 'success', text: res.data.message}, {root: true});
            resolve();
          } else {
            reject();
          }
        })
      })
    }
  }
}