export default {
  namespaced: true,
  state: {
    dituState: null
  },
  getters: {
   dituState: state => state.dituState
  },
  mutations: {
    setDituState(state, payload) {
      state.dituState = payload;
    }
  },
  actions: {
    setDituState({commit}, payload) {
      commit('setDituState', payload);
    }
  }
}