<template>
<v-app id="inspire">
  <!-- <v-main> -->
  <router-view />
  <!-- </v-main> -->
</v-app>
</template>

<script>
import Images from '@/constants/image.js';
import endpoints from "@/config/endpoints";
import { mapActions } from 'vuex';
export default {
  name: "App",
  metaInfo: {
    title: 'OKR',
    // titleTemplate: '%s | My Awesome Webapp',
    icon: Image.logo
  },
  components: {},
  data() {
    return {

    }
  },
  methods: {
    ...mapActions('app', ['getSettingCall'])
  },
  mounted() {
  //   const favicon = document.getElementById('favicon');
  //   this.getSettingCall({
  //     st_category_id: 101,
  //     st_category_type: 100
  //   }).then(res => {
  //     favicon.href = `${endpoints.FILE_DOWNLOAD}/${JSON.parse(res).file_id}`
  //   });
  }
};
</script>

<style>
* {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, \\5FAE\8F6F\96C5\9ED1, Arial, sans-serif;
  font: 14px/24px "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  /* font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", STHeitiSC, sans-serif !important; */
  box-sizing: border-box;
}
</style>
