import { DashboardService } from "../utils/api.service";
export default {
  namespaced: true,
  state: {
    dashSetting: null,
    itemStatistics: null,
    dashTrack: null,
    dashAnalyze: null,
    trackProgress: null,
    dashSettingUsers: [],
    dashSettingObjects: [],
    dashSettingObjectParents: [],
    dashKrDissolves: [],
    dashNotParentUsers: [],
    dashNotDissolveUsers: [],
    dashTrackObNotProgress: [],
    dashTrackKrNotProgress: [],
    dashTrackExeNotProgress: [],
  },
  getters: {
    dashSetting: (state) => state.dashSetting,
    itemStatistics: (state) => state.itemStatistics,
    dashTrack: (state) => state.dashTrack,
    dashAnalyze: (state) => state.dashAnalyze,
    trackProgress: (state) => state.trackProgress,
    dashSettingUsers: (state) => state.dashSettingUsers,
    dashSettingObjects: (state) => state.dashSettingObjects,
    dashSettingObjectParents: (state) => state.dashSettingObjectParents,
    dashKrDissolves: (state) => state.dashKrDissolves,
    dashNotParentUsers: (state) => state.dashNotParentUsers,
    dashNotDissolveUsers: (state) => state.dashNotDissolveUsers,
    dashTrackObNotProgress: (state) => state.dashTrackObNotProgress,
    dashTrackKrNotProgress: (state) => state.dashTrackKrNotProgress,
    dashTrackExeNotProgress: (state) => state.dashTrackExeNotProgress,
  },
  mutations: {
    getDashSetting(state, payload) {
      state.dashSetting = payload;
    },
    getItemStatistics(state, payload) {
      //state.itemStatistics = Object.assign({}, payload)
      state.itemStatistics = payload;
    },
    getTrack(state, payload) {
      state.dashTrack = payload;
    },
    getAnalyze(state, payload) {
      state.dashAnalyze = payload;
    },
    getTrackProgress(state, payload) {
      state.trackProgress = payload;
    },
    getDashSettingUsers(state, payload) {
      state.dashSettingUsers = payload;
    },
    getDashSettingObjects(state, payload) {
      state.dashSettingObjects = payload;
    },
    getDashSettingObjectParents(state, payload) {
      state.dashSettingObjectParents = payload;
    },
    getDashKrDissolves(state, payload) {
      state.dashKrDissolves = payload;
    },
    getNotParentUsers(state, payload) {
      state.dashNotParentUsers = payload;
    },
    getNotDissolveUsers(state, payload) {
      state.dashNotDissolveUsers = payload;
    },
    getTrackObNotProgress(state, payload) {
      state.dashTrackObNotProgress = payload;
    },
    getTrackKrNotProgress(state, payload) {
      state.dashTrackKrNotProgress = payload;
    },
    getTrackExeNotProgress(state, payload) {
      state.dashTrackExeNotProgress = payload;
    },
  },
  actions: {
    getSetting({ commit }, payload) {
      DashboardService.getSetting(payload).then((res) => {
        if (res.data.status !== 400) {
          commit("getDashSetting", res.data.data);
        }
      });
    },
    getItemStatistics({ commit }, payload) {
      DashboardService.getItemStatistics(payload).then((res) => {
        if (res.data.status !== 400) {
          commit("getItemStatistics", res.data.data);
        }
      });
    },
    getTrack({ commit }, payload) {
      DashboardService.getTrack(payload).then((res) => {
        if (res.status !== 400) {
          commit("getTrack", res.data.data);
        }
      });
    },
    getAnalyze({ commit }, payload) {
      DashboardService.getAnalyze(payload).then((res) => {
        if (res.status !== 400) {
          commit("getAnalyze", res.data.data);
        }
      });
    },
    getTrackProgress({ commit }, payload) {
      DashboardService.getTrackProgress(payload).then((res) => {
        if (res.status !== 400) {
          commit("getTrackProgress", res.data.data);
        }
      });
    },
    getDashSettingUsers({ commit }, payload) {
      DashboardService.getSettingUsers(payload).then((res) => {
        if (res.status == 200) {
          commit("getDashSettingUsers", res.data.data);
        }
      });
    },
    getDashSettingObjects({ commit }, payload) {
      DashboardService.getSettingObjects(payload).then((res) => {
        if (res.status !== 400) {
          commit("getDashSettingObjects", res.data.data);
        }
      });
    },
    getDashSettingObjectParents({ commit }, payload) {
      DashboardService.getSettingObjectParents(payload).then((res) => {
        if (res.status !== 400) {
          commit("getDashSettingObjectParents", res.data.data);
        }
      });
    },
    getDashKrDissolves({ commit }, payload) {
      DashboardService.getSettingKrDissolves(payload).then((res) => {
        if (res.status !== 400) {
          commit("getDashKrDissolves", res.data.data);
        }
      });
    },
    getNotParentUsers({ commit }, payload) {
      DashboardService.getNotParentUsers(payload).then((res) => {
        if (res.status !== 400) {
          commit("getNotParentUsers", res.data.data);
        }
      });
    },
    getNotDissolveUsers({ commit }, payload) {
      DashboardService.getNotDissolveUsers(payload).then((res) => {
        if (res.status !== 400) {
          commit("getNotDissolveUsers", res.data.data);
        }
      });
    },
    getTrackObNotProgress({ commit }, payload) {
      DashboardService.getTrackObNotProgress(payload).then((res) => {
        if (res.status !== 400) {
          commit("getTrackObNotProgress", res.data.data);
        }
      });
    },
    getTrackKrNotProgress({ commit }, payload) {
      DashboardService.getTrackKrNotProgress(payload).then((res) => {
        if (res.status !== 400) {
          commit("getTrackKrNotProgress", res.data.data);
        }
      });
    },
    getTrackExeNotProgress({ commit }, payload) {
      DashboardService.getTrackExeNotProgress(payload).then((res) => {
        if (res.status !== 400) {
          commit("getTrackExeNotProgress", res.data.data);
        }
      });
    },
    getAnalyzeScoreObjects({ commit }, payload) {
      return new Promise((resolve, reject) => {
        DashboardService.getAnalyzeScoreObjects(payload).then((res) => {
          if (res.status !== 400) {
            resolve(res.data.data);
          } else {
            reject();
          }
        });
      });
    },
  },
};
