/* eslint-disable */
import JwtService from "@/utils/jwt.service";
import * as endpoint from "@/config/endpoints";
import axios from "axios";
import Vue from "vue";
import VueAxios from "vue-axios";
import store from "@/store";
// import Vuex from 'vuex';
Vue.use(VueAxios, axios);
axios.defaults.headers.common["Authorization"] =
  "Bearer " + JwtService.getToken();
const checkAuth = (res) => {
  if (res?.status === 401) {
    store.dispatch("auth/logout", { root: true });
  }
  return res;
};
export const OkrService = {
  async createOkr(payload) {
    const res = await axios
      .post(endpoint.CREATE_OKR_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getOkrs(payload) {
    const res = await axios.post(endpoint.GET_OKR_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async updateOkr(payload) {
    const res = await axios
      .post(endpoint.UPDATE_OKR_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteOkr(payload) {
    const res = await axios
      .post(endpoint.DELETE_OKR_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getDetail(payload) {
    const res = await axios
      .get(`${endpoint.OBJECT_DETAILS_URL}/${payload}`)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getLibrarymenu(payload) {
    const res = await axios
      .get(endpoint.GET_LIBRARY_MENU_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getLibrarycontent(payload) {
    const res = await axios
      .post(endpoint.GET_LIBRARY_CONTENT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

// key result CRUD
export const KeyResultService = {
  async createKeyResult(payload) {
    const res = await axios
      .post(endpoint.CREATE_KEYRESULT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getKeyResults() {
    const res = await axios.get(endpoint.GET_KEYRESULT_URL).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async updateKeyResult(payload) {
    const res = await axios
      .post(endpoint.UPDATE_KEYRESULT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteKeyResult(payload) {
    const res = await axios
      .post(endpoint.DELETE_KEYRESULT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const AuthService = {
  async signup(payload) {
    const res = await axios.post(endpoint.SIGN_UP_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async login(payload) {
    const res = await axios.post(endpoint.LOGIN_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async ssoLogin(payload) {
    const res = await axios
      .get(`${endpoint.SSO_LOGIN_URL}?username=${payload}`)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const DepartmentService = {
  async getDepartments(payload) {
    const res = await axios
      .post(endpoint.GET_DEPARTMENTS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async createDepartment(payload) {
    const res = await axios
      .post(endpoint.ADD_DEPARTMENT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeDepartment(payload) {
    const res = await axios
      .post(endpoint.DELETE_DEPARTMENTS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateDepartment(payload) {
    const res = await axios
      .post(endpoint.UPDATE_DEPARTMENTS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const UserService = {
  async getUsers(payload) {
    const res = await axios
      .post(endpoint.GET_USERS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    //console.log(res);
    return res;
  },
  async updateUser(payload) {
    const res = await axios
      .post(endpoint.UPDATE_USER_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    //console.log(res);
    return res;
  },
};

export const ProgressService = {
  async getProgress(payload) {
    const res = await axios
      .post(endpoint.GET_PROGRESS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addProgress(payload) {
    const res = await axios
      .post(endpoint.ADD_PROGRESS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateProgress(payload) {
    const res = await axios
      .post(endpoint.UPDATE_PROGRESS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteProgress(payload) {
    const res = await axios
      .post(endpoint.DELETE_PROGRESS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const IntercomService = {
  async addIntercom(payload) {
    const res = await axios
      .post(endpoint.ADD_INTERCOM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getIntercom(payload) {
    const res = await axios
      .post(endpoint.GET_INTERCOM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteIntercom(payload) {
    const res = await axios
      .post(endpoint.DELETE_INTERCOM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateIntercom(payload) {
    const res = await axios
      .post(endpoint.UPDATE_INTERCOM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const OperatingService = {
  async getOperating(payload) {
    const res = await axios
      .post(endpoint.GET_OPERATING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const SettingService = {
  async createSetting(payload) {
    const res = await axios
      .post(endpoint.CREATE_SETTING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getSetting(payload) {
    const res = await axios
      .post(endpoint.GET_SETTING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateSetting(payload) {
    const res = await axios
      .post(endpoint.UPDATE_SETTING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const ReviewService = {
  async getReview(payload) {
    const res = await axios
      .post(endpoint.GET_REVIEW_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addReview(payload) {
    const res = await axios
      .post(endpoint.ADD_REVIEW_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateReview(payload) {
    const res = await axios
      .post(endpoint.UPDATE_REVIEW_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteProblem(payload) {
    const res = await axios
      .post(endpoint.DELETE_PROBLEM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const LikingService = {
  async getLiking(payload) {
    const res = await axios
      .post(endpoint.GET_LIKING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addLiking(payload) {
    const res = await axios
      .post(endpoint.ADD_LIKING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateLiking(payload) {
    const res = await axios
      .post(endpoint.UPDATE_LIKING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteLiking(payload) {
    const res = await axios
      .post(endpoint.DELETE_LIKING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const DirService = {
  async getDir(payload) {
    const res = await axios.post(endpoint.GET_DIR_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async addDir(payload) {
    const res = await axios.post(endpoint.ADD_DIR_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async updateDir(payload) {
    const res = await axios
      .post(endpoint.UPDATE_DIR_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteDir(payload) {
    const res = await axios
      .post(endpoint.DELETE_DIR_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const TaskService = {
  async getTasks(payload) {
    const res = await axios
      .post(endpoint.GET_TASKS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addTask(payload) {
    const res = await axios
      .post(endpoint.ADD_TASK_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateTask(payload) {
    const res = await axios
      .post(endpoint.UPDATE_TASK_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTask(payload) {
    const res = await axios
      .post(endpoint.GET_TASK_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteTask(payload) {
    const res = await axios
      .post(endpoint.DELETE_TASK_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const ItemService = {
  async addItem(payload) {
    const res = await axios
      .post(endpoint.ADD_ITEM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateItem(payload) {
    const res = await axios
      .post(endpoint.UPDATE_ITEM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getItems(payload) {
    const res = await axios
      .post(endpoint.GET_ITEMS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeItem(payload) {
    const res = await axios
      .post(endpoint.DELETE_ITEM_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const MilestoneService = {
  async addMilestone(payload) {
    const res = await axios
      .post(endpoint.ADD_MILESTONE_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getMilestones(payload) {
    const res = await axios
      .post(endpoint.GET_MILESTONES_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateMilestone(payload) {
    const res = await axios
      .post(endpoint.UPDATE_MILESTONE_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeMilestone(payload) {
    const res = await axios
      .post(endpoint.DELETE_MILESTONE_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const WorkingTimeService = {
  async getWorkingTimeByTaskID(payload) {
    const res = await axios
      .post(endpoint.GET_WORKINGTIME_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addWorkingTime(payload) {
    const res = await axios
      .post(endpoint.ADD_WORKINGTIME_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateWorkingTime(payload) {
    const res = await axios
      .post(endpoint.UPDATE_WORKINGTIME_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteWorkingTime(payload) {
    const res = await axios
      .post(endpoint.DELETE_WORKINGTIME_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const KanbanService = {
  async addKanban(payload) {
    const res = await axios
      .post(endpoint.ADD_KANBAN_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getKanbans(payload) {
    const res = await axios
      .post(endpoint.GET_KANBANS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateKanban(payload) {
    const res = await axios
      .post(endpoint.UPDATE_KANBAN_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async deleteKanban(payload) {
    const res = await axios
      .post(endpoint.REMOVE_KANBAN_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const ReportService = {
  async addReport(payload) {
    const res = await axios
      .post(endpoint.ADD_REPORT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getReport(payload) {
    const res = await axios
      .post(endpoint.GET_REPORT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateReport(payload) {
    const res = await axios
      .post(endpoint.UPDATE_REPORT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeReport(payload) {
    const res = await axios
      .post(endpoint.DELETE_REPORT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const FileUploadService = {
  async fileDownload(id) {
    const res = axios.get(endpoint.FILE_DOWNLOAD + "/" + id).catch((err) => {
      return checkAuth(err.response);
    });
  },
  async fileUpload(payload) {
    const res = await axios.post(endpoint.FILE_UPLOAD, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async deleteFileUploadById(payload) {
    const res = await axios
      .post(endpoint.DELETE_FILEUPLOAD, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getFiles() {
    const res = await axios.post(endpoint.GET_FILES, {}).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async updateFile(payload) {
    const res = await axios.post(endpoint.UPDATE_FILE, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
};

//导入数据
export const ImportExcelService = {
  async userImportExcel(payload) {
    console.log(endpoint.USER_IMPORTEXCEL, "endpoint.USER_IMPORTEXCEL");
    const res = await axios
      .post(endpoint.USER_IMPORTEXCEL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const DashboardService = {
  async getSetting(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_SETTING_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getItemStatistics(payload) {
    const res = await axios
      .post(endpoint.ITEM_STATISTICS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTrack(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACK_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getAnalyze(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_ANALYZE_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTrackProgress(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACKPROGRESS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getSettingUsers(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_SETTING_USERS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getSettingObjects(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_SETTING_OBJECTS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getSettingObjectParents(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_OBJECT_PARENTS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getSettingKrDissolves(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_KR_DISSOLVES, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getNotParentUsers(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_OBJECT_NOTPARENT_USERS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getNotDissolveUsers(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_KR_NOTDISSOLVE_USERS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTrackObNotProgress(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACK_OBNOTPROGRESS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTrackKrNotProgress(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACK_KRNOTPROGRESS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getTrackExeNotProgress(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACK_EXENOTPROGRESS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async getAnalyzeScoreObjects(payload) {
    const res = await axios
      .post(endpoint.DASHBOARD_TRACK_ANALYZESCOREOBJECTS, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};

export const LabelService = {
  async getLabels() {
    const res = await axios.post(endpoint.GET_TAGS_URL, {}).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
  async addLabel(payload) {
    const res = await axios.post(endpoint.ADD_TAG_URL, payload).catch((err) => {
      return checkAuth(err.response);
    });
    return res;
  },
};
export const KrService = {
  async getKeyResults(payload) {
    const res = await axios
      .post(endpoint.GET_KEYRESULT_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const GroupService = {
  async getGroups(payload) {
    const res = await axios
      .post(endpoint.GET_GROUPS_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addGroup(payload) {
    const res = await axios
      .post(endpoint.ADD_GROUP_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateGroup(payload) {
    const res = await axios
      .post(endpoint.UPDATE_GROUP_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeGroup(payload) {
    const res = await axios
      .post(endpoint.REMOVE_GROUP_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
export const DutyService = {
  async getDuties(payload) {
    const res = await axios
      .post(endpoint.GET_DUTIES_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async addDuty(payload) {
    const res = await axios
      .post(endpoint.ADD_DUTY_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async updateDuty(payload) {
    const res = await axios
      .post(endpoint.UPDATE_DUTY_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
  async removeDuty(payload) {
    const res = await axios
      .post(endpoint.REMOVE_DUTY_URL, payload)
      .catch((err) => {
        return checkAuth(err.response);
      });
    return res;
  },
};
