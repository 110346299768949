/*
 * @Author: niweixing
 * @Date: 2022-11-29 17:56:02
 * @LastEditors: niweixing
 * @LastEditTime: 2023-08-21 23:43:17
 * @Description:
 */
export const Routes = {
  POSTER_PAGE: "/poster",
  POSTER_ABOUT_PAGE: "/poster/about",
  HOME_PAGE: "/home",
  SHARE_PAGE: "/share",
  TASK_PRINT_PAGE: "/share/task_print",
  TASK_GANTT_PAGE: "/home/task_gantt",
  HOME_SECOND_PAGE: "/home_second",
  AUTH_PAGE: "/auth",
  LOGIN_PAGE: "/auth/login",
  SIGNUP_PAGE: "/auth/signup",
  OKR_GOAL_PAGE: "/home/okr_goal",
  ADMIN_PAGE: "/admin",
  INFO_SETTING_PAGE: "/home/info_setting",
  ADMIN_EMPLOYEE_PAGE: "/admin/employee",
  DYNAMIC_PAGE: "/home/okr_dynamic",
  OKR_LIST_PAGE: "/home/okr_goal/list",
  OKR_EDIT_PAGE: "/home/okr_goal/:ob_id",
  OKR_EREPORT_PAGE: "/home/okr_ereport",
  ADMIN_RESIGN_PAGE: "/admin/resign",
  ADMIN_EXTERNAL_PAGE: "/admin/external_member",
  ADMIN_GROUP_PAGE: "/admin/group",
  ADMIN_JOB_PAGE: "/admin/job_title",
  ADMIN_DEPARTMENT_PAGE: "/admin/department",
  ADMIN_DUTY_PAGE: "/admin/duty",
  ADMIN_MANAGE_PAGE: "/admin/management",
  ADMIN_COMPANY_PAGE: "/admin/company",
  ADMIN_COMPANYINFO_PAGE: "/admin/company/info",
  ADMIN_EMAIL_PAGE: "/admin/company/email",
  OKR_SETTING_PAGE: "/home/setting",
  OKR_IMPORT_LIBRARY: "/home/library",
  OKR_MAP_PAGE: "/home/okr_map",
  OKR_WORKING_METHOD: "/home/working_method",
  OKR_WORKING_METHOD_HOME: "/home/working_method/main",
  OKR_DASHBOARD_PAGE: "/home/dashboard",
  EXE_SCHEDULE_PAGE: "/home/schedule",
  EXE_SCHEDULE_PAGE_HOME: "/home/schedule/main",
  EXE_PROJECT_PAGE: "/home/project",
  EXE_SUMMARY_PAGE: "/home/summary",
  SUMMARY_MAIN_PAGE: "/home/summary/main",
  ITEM_DETAIL_PAGE: "/home/project/detail",
  TASK_DETAIL_PAGE: "/home/task/detail",
  WORKING_REPORT_PAGE: "/home/working_report",
  ADVANCED_FUNC_PAGE: "/admin/advanced_func",
  OKR_MESSAGE_PAGE: "/home/message",
};
