/*
 * @Author: niweixing
 * @Date: 2021-11-01 14:32:37
 * @LastEditors: niweixing
 * @LastEditTime: 2022-06-08 16:44:23
 * @Description:
 */
import { OperatingService } from "../utils/api.service";

export default {
  namespaced: true,
  state: {
    operators: [],
  },
  getters: {
    operators: (state) => state.operators,
  },
  mutations: {
    getOperators(state, ops) {
      state.operators = ops;
    },
  },
  actions: {
    getOperators({ commit }, payload) {
      return OperatingService.getOperating(payload).then((res) => {
        if (res.status == 200) {
          commit("getOperators", res.data.data);
          return res.data.data;
        }
      });
    },
  },
};
