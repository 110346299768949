import { ReportService } from '../utils/api.service';
import { messages } from '@/constants/messages';

export default {
    namespaced: true,
    state: {
        allDailyReports: [],
        allWeeklyReports: [],
        allMonthlyReports: [],
    },
    getters: {
        allDailyReport: state => state.allDailyReports,
        allWeeklyReport: state => state.allWeeklyReports,
        allMonthlyReport: state => state.allMonthlyReports,
    },
    mutations: {
        addDailyReport(state, payload) {
            state.allDailyReports.push(payload);
        },
        addWeeklyReport(state, payload) {
            state.allWeeklyReports.push(payload)
        },
        addMonthlyReport(state, payload) {
            state.allMonthlyReports.push(payload)
        },
        // getDailyReport(state, re) {
        //     state.dailyReport = re;
        // },
        // getWeeklyReport(state, re) {
        //     state.weeklyReport = re;
        // },
        // getMonthlyReport(state, re) {
        //     state.monthlyReport = re;
        // },
        getAllDailyReportWithCreator(state, re) {
            state.allDailyReports = re;
        },
        getAllWeeklyReportWithCreator(state, re) {
            state.allWeeklyReports = re;
        },
        getAllMonthlyReportWithCreator(state, re) {
            state.allMonthlyReports = re;
        },
        updateDailyReport(state, payload) {
            const index = state.allDailyReports.findIndex(report => report.rp_id == payload.rp_id);
            if (index !== -1) {
                Object.assign(state.allDailyReports[index], payload);
            }
        },
        updateWeeklyReport(state, payload) {
            const index = state.allWeeklyReports.findIndex(report => report.rp_id == payload.rp_id);
            if (index !== -1) {
                Object.assign(state.allWeeklyReports[index], payload);
            }
        },
        updateMonthlyReport(state, payload) {
            const index = state.allMonthlyReports.findIndex(report => report.rp_id == payload.rp_id);
            if (index !== -1) {
                Object.assign(state.allMonthlyReports[index], payload);
            }
        },
        removeReport(state, payload) {
            if (payload.rp_type == 0) {
                const index = state.allDailyReports.findIndex(report => report.rp_id == payload.rp_id);
                if (index !== -1) {
                    state.allDailyReports.splice(index, 1);
                }
            } else if (payload.rp_type == 1) {
                const index = state.allWeeklyReports.findIndex(report => report.rp_id == payload.rp_id);
                if (index !== -1) {
                    state.allWeeklyReports.splice(index, 1);
                }
            } else if (payload.rp_type == 2) {
                const index = state.allMonthlyReports.findIndex(report => report.rp_id == payload.rp_id);
                if (index !== -1) {
                    state.allMonthlyReports.splice(index, 1);
                }
            }
        }
    },
    actions: {
        addDailyReport({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.addReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("addDailyReport", res.data.data);
                        dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
                        resolve(res.data.data);
                    } else {
                        dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
                        reject();
                    }
                })
            })
        },
        addWeeklyReport({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.addReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("addWeeklyReport", res.data.data);
                        dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
                        resolve(res.data.data);
                    } else {
                        dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
                        reject();
                    }
                })
            })
        },
        addMonthlyReport({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.addReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("addMonthlyReport", res.data.data);
                        dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
                        resolve(res.data.data);
                    } else {
                        dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
                        reject();
                    }
                })
            })
        },
				getReportCall({ dispatch, commit }, payload) {
					return ReportService.getReport(payload).then((res) => {
						if (res.status != 400) {
							return res.data.data;
						} else {
							return false;
						}
					})
				},
        getDailyReport({ dispatch, commit }, payload) {
            ReportService.getReport(payload).then((res) => {
                if (res.status != 400) {
                    commit("getDailyReport", res.data.data[res.data.data.length - 1]);
                }
            })
        },
        getWeeklyReport({ dispatch, commit }, payload) {
            ReportService.getReport(payload).then((res) => {
                if (res.status != 400) {
                    commit("getWeeklyReport", res.data.data[res.data.data.length - 1]);
                }
            })
        },
        getAllDailyReportWithCreator({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.getReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("getAllDailyReportWithCreator", res.data.data);
                        resolve(res.data.data);
                    } else {
                        reject();
                    }
                })
            })
            
        },
        getAllWeeklyReportWithCreator({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.getReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("getAllWeeklyReportWithCreator", res.data.data);
                        resolve(res.data.data);
                    } else {
                        reject();
                    }
                })
            })            
        },
        getMonthlyReport({ dispatch, commit }, payload) {
            ReportService.getReport(payload).then((res) => {
                if (res.status != 400) {
                    commit("getMonthlyReport", res.data.data[res.data.data.length - 1]);
                }
            })
        },
        getAllMonthlyReportWithCreator({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.getReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("getAllMonthlyReportWithCreator", res.data.data);
                        resolve(res.data.data);
                    } else {
                        reject();
                    }
                });
            })            
        },
        updateDailyReport({ dispatch, rootGetters, commit }, payload) {
            return new Promise((resolve, reject) => {
                ReportService.updateReport(payload).then((res) => {
                    if (res.status != 400) {
                        commit("updateDailyReport", payload);
                        dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
                        resolve(payload);
                    } else {
                        dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
                        reject();
                    }
                })
            })

		},
		updateWeeklyReport({ dispatch, commit }, payload) {
			return new Promise((resolve, reject) => {
				ReportService.updateReport(payload).then((res) => {
					if (res.status != 400) {
						commit("updateWeeklyReport", payload);
						dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
						resolve(payload);
					} else {
						dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
						reject();
					}
				})
			})

		},
		updateMonthlyReport({ dispatch, commit }, payload) {
			return new Promise((resolve, reject) => {
				ReportService.updateReport(payload).then((res) => {
					if (res.status != 400) {
						commit("updateMonthlyReport", payload);
						dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
						resolve(payload);
					} else {
						dispatch('app/setNotification', { status: 'warning', text: messages.SOMETHING_WENT_WRONG }, { root: true });
						reject();
					}
				})
			})
		},
		removeReport({ dispatch, commit }, payload) {
			ReportService.removeReport(payload).then(res => {
				if (res.status !== 400) {
					commit('removeReport', payload);
					dispatch('app/setNotification', { status: 'success', text: res.data.message }, { root: true });
				}
			})
		}
	}
}